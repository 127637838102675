import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import './styles/theme.scss';
import Layout from './components/Layout/Layout';
import Login from './pages/Auth/login/Login';
import Verify from './pages/Auth/verify/Verify';
import Register from './pages/Auth/register/Register';
import Reset from './pages/Auth/reset/Reset';
import Forgot from './pages/Auth/forgot/Forgot';
import Error from './pages/Error/Error';
import useAuth from './hooks/useAuth';
import VerifyEmail from './pages/Auth/verifyEmail/VerifyEmail';

function App() {

  const { isAuthenticated } = useAuth();

  return (
    <Routes>
      <Route path='/' element={<Navigate to='/login'/>}/>
      <Route path='/login' element={<Login/>}/>
      <Route path='/register' element={<Register/>}/>
      <Route path='/verify-email' element={<VerifyEmail/>}/>
      <Route path='/forgot' element={<Forgot/>}/>
      <Route path='/password-reset' element={<Reset/>}/>
      <Route path='/error' element={<Error/>}/>
      <Route path='/*' element={isAuthenticated ? <Layout/> : <Login/>}/>
    </Routes>
  );
}

export default App;
